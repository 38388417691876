import React, { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Input,
  Box,
} from '@chakra-ui/react';
import { Control, FieldErrors, UseFormHandleSubmit } from 'react-hook-form';
import { FormField } from './FormField';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message: string;
  onConfirm: () => void;
  isConfirming?: boolean;
  confirmText?: string;
  cancelText?: string;
  isInput?: boolean;
  maxQuantity?: number;
  handleSubmit?: UseFormHandleSubmit<any>;
  control?: Control<any>;
  reset?:any;
  errors?: FieldErrors<any>;
}

const ConfirmDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  title,
  message,
  onConfirm,
  isConfirming = false,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  isInput = false,
  handleSubmit,
  control,
  errors,
  reset
}) => {
  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={() => {
      onClose();
      reset();
    }}>
      <Box as={isInput ? 'form' : 'div'} onSubmit={handleSubmit && handleSubmit(onConfirm)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>
              {message}
              <Box mt={5}>
                {isInput && control && errors && (
                  <FormField
                    fieldName={'quantity'}
                    errors={errors}
                    
                    control={control}
                    render={({ field }: any) => <Input {...field}  type="number" disabled={isConfirming} autoFocus placeholder={'Enter Quantity*'} />}
                  />
                )}
              </Box>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} variant="outline">
                {cancelText}
              </Button>
              <Button
                type={isInput ? 'submit' : 'button'}
                onClick={isInput ? handleSubmit && handleSubmit(onConfirm) : onConfirm}
                ml={3}
                isLoading={isConfirming}
              >
                {confirmText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </Box>
    </AlertDialog>
  );
};

export default ConfirmDialog;
