import React, { useEffect, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';

import DataTabs from './XTabs';
import CompactTable from './CompactTable';
import InfoTooltip from './InfoTooltip';

import api from '../api';
import { useAuth } from '../AuthContext';

interface Props {}

const tabData = [
  {
    label: 'impactX',
    content: 'score',
  },
  {
    label: 'Closed loops',
    content: 'closed',
  },
];
const ScoreCard: React.FC<Props> = () => {
  const { user } = useAuth();

  const config = {
    page:1,
    limit:1000
  }

  const [departments, setDepartments] = useState([]);
  const [topScorers, setTopScorers]: any = useState([]);
  const [topClosers, setTopClosers]: any = useState([]);
  useEffect(() => {
    setTopScorers(
      departments
        .sort((d1: any, d2: any) => d2.score?.impactXScore - d1.score?.impactXScore)
        .map((department: any) => ({ department: department.name, score: department.score.impactXScore, up: true }))
    );

    setTopClosers(
      departments
        .sort((d1: any, d2: any) => d2.score?.closeLoop - d1.score?.closeLoop)
        .map((department: any) => ({ department: department.name, score: department.score.closeLoop, up: true }))
    );
  }, [departments]);

  const fetchData = async () => {
    if (!user?.organisation?.id) {
      return;
    }

    try {
      const { data } = await api.get(`/departments/organization/${user?.organisation?.id}`,{params:config});

      setDepartments(data.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const [tableData, setableData] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (index: number, content: string) => {
    setTabIndex(index);
  };

  return (
    <Box bg={'brand.900'} borderRadius={'30px'}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} color="#fff" fontSize={'24px'} px="5" pt="3" pb="3">
        <Text>Sustainability Challenge</Text>
        <InfoTooltip title={'How is your department doing?'} iconColor={'rgba(255, 255, 255, .5)'}>
          See how your team is doing in its sustainability efforts against others in your business. Can you reach the top of the table? 
        </InfoTooltip>
      </Box>
      <DataTabs compact data={tabData} onTabChange={handleTabChange}>
        <Box maxHeight={'200px'} overflow={'scroll'}>
          <CompactTable data={tabIndex === 0 ? topScorers : topClosers} />
        </Box>
      </DataTabs>
    </Box>
  );
};

export default ScoreCard;
